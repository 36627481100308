.content {
  display: flex;
  flex-direction: row;
  flex: 1 1 0%;

  main {
    // position: relative;
    // padding-top: 2rem;
    // padding-bottom: 2rem;
    // flex: 1 1 0%;
    position: relative;
    padding-top: 2rem;
    padding-bottom: 2rem;
    flex: 1 1 0%;

    @media (min-width: 1024px) {
      padding-left: 3rem;
      padding-right: 3rem;
    }

    header {
      display: flex;
      align-items: center;
      height: 72px;
      padding: 0 1.25rem 0 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.08);

      margin-bottom: 1.5rem;
      margin-top: 0.75rem;
      margin-top: 0.875rem;
      max-width: 48rem;
    }

    .page-content {
      display: grid;
      white-space: pre-wrap;
    }
  }

  aside {
    display: none; /* Initially hidden */
    position: sticky;
    top: 4rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    flex-basis: 14rem;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    height: 100vh;

    @media (min-width: 1024px) {
      height: 100%;
      max-height: calc(100vh - 4rem);
    }

    @media (min-width: 1280px) {
      display: flex; /* Show aside on larger screens */
    }
  }
}
