.sidebar-burger {
  background: none;
  border: none;
  color: rgb(255, 0, 0);
  font-size: 24px;
  cursor: pointer;

  &.show-burger {
    position: fixed;
    top: 30px !important;
    left: 50px !important;
    background: rgba(0, 0, 0, 0.4);
    border: none;
    border-radius: 8px;
    padding: 10px;
  }
}

.sidebar1 {
  display: flex;
  position: relative;
  top: 0;
  flex-basis: 100%;
  flex-direction: column;
  flex-grow: 0;
  shrink: 0;
  height: 100vh;

  border: 1px solid rgba(255, 255, 255, 0.08);
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(20px);
  transition: width 0.4s, transform 0.3s ease-in-out;
  border-radius: 16px;

  // button {
  //   background: transparent;
  //   border: none;
  //   cursor: pointer;
  //   display: flex;
  //   align-items: center;
  //   gap: 16px;
  //   height: 50px;
  //   width: 100%;
  //   border-radius: 6px;
  //   font-family: inherit;
  //   font-size: 16px;
  //   font-weight: 400;
  //   color: rgba(255, 255, 255, 0.95);
  //   padding: 0 16px;
  //   transition: background 0.3s; /* Transition for background change */

  //   p {
  //     flex-grow: 1; /* Allow paragraph to grow and take available space */
  //     text-align: left;
  //   }

  //   &:is(.active, :hover) {
  //     background: rgba(0, 0, 0, 0.3);
  //   }

  //   &:not(.active):hover {
  //     background: rgba(0, 0, 0, 0.1);
  //   }

  //   .submenu-icon {
  //     margin-left: auto; /* Align icon to the right */
  //     transition: transform 0.3s ease;

  //     &.active {
  //       transform: rotate(180deg);
  //     }
  //   }
  // }

  button {
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 16px;
    height: 50px;
    width: 100%;
    border-radius: 6px;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.95); /* Default text color */
    padding: 0 16px;
    transition: background 0.3s, color 0.3s; /* Transition for background and color change */

    p {
      flex-grow: 1; /* Allow paragraph to grow and take available space */
      text-align: left;
    }

    &.active {
      background: rgba(0, 0, 0, 0.3);
      color: blue; /* Change text color to blue when active */
    }

    &:hover:not(.active) {
      background: rgba(0, 0, 0, 0.1);
      color: rgba(
        255,
        255,
        255,
        0.95
      ); /* Keep text color white on hover if not active */
    }

    .submenu-icon {
      margin-left: auto; /* Align icon to the right */
      transition: transform 0.3s ease;

      &.active {
        transform: rotate(180deg);
      }
    }
  }

  header {
    display: flex;
    align-items: center;
    height: 72px;
    padding: 0 1.25rem 0 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);

    button {
      width: 52px; /* Fixed width for the button */
    }

    img {
      height: 24px; /* Consistent image height */
    }
  }

  ul {
    display: flex; /* Use flex instead of grid */
    flex-direction: column; /* Stack items in a column */
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .material-symbols-outlined {
    font-size: 16px; /* Consistent font size */
  }

  &.hidden {
    transform: translateX(-200%); /* Use transform for hidden effect */
  }

  @media (min-width: 1024px) {
    position: sticky;
    top: 4rem;
    flex-basis: 18rem;
    height: calc(100vh_-_4rem);
  }

  .sidebar-container {
    display: none;
    overflow-y: auto;
    padding-top: 1rem;
    flex-direction: column;
    flex-grow: 1;

    @media (min-width: 1024px) {
      display: flex;
      padding-right: 0.5rem;
      padding-bottom: 5rem;
      // margin-left: -1.25rem;
    }
  }
}


