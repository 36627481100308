.layout {
  display: flex;
  // padding-left: 1rem;
  // padding-right: 1rem;
  flex-direction: column;
  max-width: 1536px;
  // background-color: yellow;
  justify-content: center; /* Center content vertically */
  // align-items: center; /* Center content horizontally */
  margin: 0 auto; /* Center layout horizontally on the page */

  @media (min-width: 640px) {
    // padding-left: 1.5rem;
    // padding-right: 1.5rem;
  }

  @media (min-width: 768px) {
    // padding-left: 2rem;
    // padding-right: 2rem;
  }

  @media (min-width: 1024px) {
    flex-direction: row; /* Change to row direction on larger screens */
    justify-content: center; /* Center content horizontally */
    // align-items: center; /* Center content vertically */
  }
}